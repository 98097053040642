<template>
  <InputText
    :type="'text'"
    :name="'business-name'"
    :autocomplete="'off'"
    :spellcheck="false"
    :label="'Wpisz nazwę agencji'"
    :placeholder="'The Best L.L.C.'"
    :disabled="disabled"
    @update:model-value="$emit('update:modelValue', $event)"
    :model-value="modelValue"
  />
</template>

<script>
export default {
  props: {
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],
};
</script>
