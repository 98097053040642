<template>
  <div class="wrapper-inputs">
    <InputTextNameFirst v-model="userProfile.nameFirst" :disabled="true" />

    <div class="wrapper-zip wrapper-inputs__wrapper-zip">
      <InputTextZipValidation
        v-model="userProfile.zip"
        @update:is-valid-zip="isValidZip = $event"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "vue";
import { useUserProfileStore } from "@/stores/userProfile";

import InputTextZipValidation from "@/components/UI/InputTextZipValidation.vue";
import InputTextNameFirst from "@/components/UI/InputTextNameFirst.vue";
import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";

export default {
  components: {
    InputTextZipValidation,
    InputTextNameFirst,
  },

  emits: ["update:isValid", "profileChange"],

  setup(props, { emit }) {
    const userProfile = useUserProfileStore();
    const { isInited: isGoogleMapsInited, zipToCoordsLatLng } =
      useGoogleMapsGeocoder();
    const isValidZip = ref(false);

    watch(
      () => userProfile.zip,
      () => {
        emit("profileChange");
      }
    );
    watch(
      isValidZip,
      (isValidZip) => {
        emit("update:isValid", isValidZip);
        if (isValidZip) {
          setZipLocation(userProfile.zip);
        }
      },
      { immediate: true }
    );

    const setZipLocation = async (zip) => {
      console.log("SET_ZIP_LOCATION_POINT");
      if (!isValidZip.value || !isGoogleMapsInited.value) return;
      const zipCoordsLatLng = await zipToCoordsLatLng(zip);
      console.log({ zipCoordsLatLng });
      if (zipCoordsLatLng === null) {
        // If coords were not found --> set null and return
        userProfile.zipLocationPoint = null;
        return;
      }
      const zipCoordsLngLat = zipCoordsLatLng.slice().reverse();
      userProfile.zipLocationPoint = {
        coordinates: zipCoordsLngLat,
        type: "Point",
      };
    };

    return {
      userProfile,
      isValidZip,
    };
  },
};
</script>

<style scoped>
.wrapper-inputs {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}
.wrapper-inputs__wrapper-zip {
  width: 100%;
}
</style>
