<template>
  <div class="wrapper-inputs">
    <InputTextNameFirst :model-value="userProfile.nameFirst" :disabled="true" />
    <InputTextNameLast :model-value="userProfile.nameLast" :disabled="true" />

    <div class="wrapper-sex-age wrapper-inputs__wrapper-sex-age">
      <SelectBlockSex :model-value="userProfile.sex" :disabled="true" />
      <InputTextDOB v-model:dob-date="userProfile.dob" :disabled="true" />
    </div>

    <div class="wrapper-ZIP wrapper-inputs__wrapper-ZIP">
      <InputTextZipValidation
        v-model="userProfile.zip"
        @update:is-valid-zip="isValid.zip = $event"
      />
    </div>

    <BlockTelInput
      v-model:phone="userProfile.phone"
      @update:is-verified="isValid.phone = $event"
    />
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import { useUserProfileStore } from "@/stores/userProfile";
import { storeToRefs } from "pinia";

import InputTextZipValidation from "@/components/UI/InputTextZipValidation.vue";
import BlockTelInput from "@/components/BlockTelInput.vue";
import InputTextNameFirst from "@/components/UI/InputTextNameFirst.vue";
import InputTextNameLast from "@/components/UI/InputTextNameLast.vue";
import SelectBlockSex from "@/components/UI/SelectBlockSex.vue";
import InputTextDOB from "@/components/UI/InputTextDOB.vue";
import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";

export default {
  components: {
    InputTextZipValidation,
    BlockTelInput,
    InputTextNameFirst,
    InputTextNameLast,
    SelectBlockSex,
    InputTextDOB,
  },

  emits: ["update:isValid", "profileChange"],

  setup(props, { emit }) {
    const { zipToCoordsLatLng, isInited: isGoogleMapsInited } =
      useGoogleMapsGeocoder();
    const userProfile = useUserProfileStore();
    const { zip, phone } = storeToRefs(userProfile);
    const isValid = reactive({
      zip: false,
      phone: false,
      get value() {
        return this.zip && this.phone;
      },
    });

    watch([zip, phone], () => {
      emit("profileChange");
    });
    watch(
      () => isValid.value,
      (newIsValid) => {
        emit("update:isValid", newIsValid);
      },
      { immediate: true }
    );
    watch(
      () => isValid.zip,
      (isValid) => {
        if (isValid === false) return;

        setZipLocation(userProfile.zip);
      }
    );

    const setZipLocation = async (zip) => {
      console.log("SET_ZIP_LOCATION_POINT");
      if (!isValid.zip || !isGoogleMapsInited) return;
      const zipCoordsLatLng = await zipToCoordsLatLng(zip);
      if (zipCoordsLatLng === null) {
        // If coords were not found --> set null and return
        userProfile.zipLocationPoint = null;
        return;
      }
      const zipCoordsLngLat = zipCoordsLatLng.slice().reverse();
      userProfile.zipLocationPoint = {
        coordinates: zipCoordsLngLat,
        type: "Point",
      };
    };

    return {
      userProfile,
      isValid,
    };
  },
};
</script>

<style scoped>
.wrapper-inputs {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}
.wrapper-inputs__wrapper-sex-age {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.wrapper-inputs__wrapper-ZIP {
  width: 100%;
}
</style>
