<template>
  <LayoutViewWrapperNarrow>
    <div class="content">
      <BaseH1 :text="'Profil'" :short="true" />

      <component
        @update:is-valid="isValid = $event"
        @profile-change="isProfileChanged = true"
        :is="profileComponent"
      />

      <ButtonPrimarySave
        v-if="isProfileChanged && !isMobile"
        @click="updateProfile()"
        :isDisabled="isButtonDisabled"
      />
    </div>
    <NavMobileBackSave
      v-if="isMobile"
      @save="updateProfile()"
      :isSaveButtonVisible="isProfileChanged"
      :isSaveButtonDisabled="isButtonDisabled"
    />
  </LayoutViewWrapperNarrow>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useUserStore } from "@/stores/user";
import { useUserProfileStore } from "@/stores/userProfile";

import useIsMobile from "@/composables/useIsMobile";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import AccountProfileContractor from "@/views/Account/Profile/AccountProfileContractor.vue";
import AccountProfileClient from "@/views/Account/Profile/AccountProfileClient.vue";
import ButtonPrimarySave from "@/components/UI/ButtonPrimarySave.vue";
import AccountProfileClientBusiness from "@/views/Account/Profile/AccountProfileClientBusiness.vue";
import NavMobileBackSave from "@/components/NavMobileBackSave.vue";
import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";

export default {
  components: {
    BaseH1,
    ButtonPrimarySave,
    NavMobileBackSave,
    LayoutViewWrapperNarrow,
  },

  setup() {
    const { isMobile } = useIsMobile();
    const user = useUserStore();
    const userProfile = useUserProfileStore();
    const isValid = ref(false);
    const isProfileChanged = ref(false);
    const profileComponent = computed(() => {
      const { userType, accountType } = user;

      if (userType === "client") {
        if (accountType === "sole") {
          return AccountProfileClient;
        }

        if (accountType === "business") {
          return AccountProfileClientBusiness;
        }
      }

      if (userType === "contractor") {
        return AccountProfileContractor;
      }

      return "div";
    });
    const isButtonDisabled = computed(() => {
      return !isValid.value;
    });

    const updateProfile = async () => {
      try {
        if (isValid.value === false || isButtonDisabled.value === true) return;

        await userProfile.updateUserProfile();
        isProfileChanged.value = false;
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    return {
      isMobile,
      profileComponent,
      isValid,
      isButtonDisabled,
      isProfileChanged,
      updateProfile,
    };
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}
</style>
