<template>
  <div class="wrapper-inputs">
    <InputTextNameFirst v-model="userProfile.nameFirst" :disabled="true" />
    <InputTextNameBusiness
      v-model="userProfile.nameBusiness"
      :disabled="true"
    />

    <div class="wrapper-zip wrapper-inputs__wrapper-zip">
      <InputTextZipValidation
        v-model="userProfile.zip"
        @update:is-valid-zip="isValid.zip = $event"
      />
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from "vue";
import { useUserProfileStore } from "@/stores/userProfile";

import InputTextZipValidation from "@/components/UI/InputTextZipValidation.vue";
import InputTextNameFirst from "@/components/UI/InputTextNameFirst.vue";
import InputTextNameBusiness from "@/components/UI/InputTextNameBusiness.vue";
import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";

export default {
  components: {
    InputTextZipValidation,
    InputTextNameFirst,
    InputTextNameBusiness,
  },

  emits: ["update:isValid", "profileChange"],

  setup(props, { emit }) {
    const userProfile = useUserProfileStore();
    const { zipToCoordsLatLng, isInited: isGoogleMapsInited } =
      useGoogleMapsGeocoder();

    const isValid = reactive({
      zip: false,
      get value() {
        return this.zip;
      },
    });

    watch(
      () => userProfile.zip,
      () => {
        emit("profileChange");
      }
    );
    watch(
      () => isValid.value,
      (newIsValid) => {
        emit("update:isValid", newIsValid);
      },
      { immediate: true }
    );
    watch(
      () => isValid.zip,
      (isValid) => {
        if (!isValid) return;

        setZipLocation(userProfile.zip);
      }
    );

    const setZipLocation = async (zip) => {
      console.log("SET_ZIP_LOCATION_POINT");
      if (!isValid.zip || !isGoogleMapsInited) return;
      const zipCoordsLatLng = await zipToCoordsLatLng(zip);
      if (zipCoordsLatLng === null) {
        // If coords were not found --> set null and return
        userProfile.zipLocationPoint = null;
        return;
      }
      const zipCoordsLngLat = zipCoordsLatLng.slice().reverse();
      userProfile.zipLocationPoint = {
        coordinates: zipCoordsLngLat,
        type: "Point",
      };
    };

    return {
      userProfile,
      isValid,
    };
  },
};
</script>

<style scoped>
.wrapper-inputs {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}
.wrapper-inputs__wrapper-zip {
  width: 100%;
}
</style>
